import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"
import PlaylistItem from "../components/PlaylistItem"

const PlaylistPage = function ({
  data: { playlistJson, allPlaylistItemsJson },
  location,
}) {
  const [inputs, setInputs] = useState({})

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    setInputs((inputsData) => ({
      ...inputsData,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <Layout>
      <Seo
        title={playlistJson.title}
        description={playlistJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={playlistJson.banner.background}
        content={playlistJson.banner.content}
      />
      <div className="container">
        <section>
          <h2>{playlistJson.title}</h2>

          <form className="form-inline mb-6" onSubmit={handleSubmit}>
            <label htmlFor="date">
              {playlistJson.searchForm.date}
              <input
                type="date"
                name="date"
                required
                onChange={handleInputChange}
              />
            </label>

            <label htmlFor="time" className="mr-8">
              {playlistJson.searchForm.time}
              <input
                type="time"
                name="time"
                required
                onChange={handleInputChange}
              />
            </label>

            <button type="submit" className="btn btn-primary">
              <i className="fa fa-search" />
              {playlistJson.searchForm.submit}
            </button>
          </form>

          <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-5 mb-5">
            {allPlaylistItemsJson.nodes.map(function (item) {
              return <PlaylistItem key={uuidv4()} item={item} />
            })}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

PlaylistPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default PlaylistPage

export const query = graphql`
  query PlaylistPageQuery {
    playlistJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      searchForm {
        date
        time
        submit
      }
    }
    allPlaylistItemsJson {
      nodes {
        artist
        title
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
